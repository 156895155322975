import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

import { Player, usePlayerMoneyFormatter } from "@entities/player";

export function ProfileBalanceDetails({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const { data: data } = Player.useBalances();

  const balances = data?.balance;
  const wager = data?.wager;

  return (
    <div className={clsx(className, "lg:text-sm text-blue-bayoux font-bold text-xs uppercase")}>
      <div className="grid grid-cols-2 gap-y-1">
        <div>{$t({ defaultMessage: "main balance" })}</div>
        <div className="justify-self-end">{formatMoney(balances?.balance ?? 0)}</div>
        <hr className="col-span-2" />
        <div>{$t({ defaultMessage: "wager" })}</div>
        <div className="justify-self-end">{formatMoney(wager?.remaining ?? 0)}</div>
      </div>
    </div>
  );
}
