import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useContext, useState } from "react";

import { Player, PlayerSettingsContext } from "@entities/player";
import { PlayerBalance } from "@features/player/player-balance";
import { emitter } from "@shared/lib";
import { Button, SVGIcon } from "@shared/ui";

export function ProfilePlayerBalanceView({ className }: { className?: string }) {
  const { $t } = useTranslate();

  const playerSettings = useContext(PlayerSettingsContext);

  const { data: player } = Player.usePlayer();
  const { refetch, isFetching } = Player.useBalances();

  const [isDummyFetching, setIsDummyFetching] = useState(false);

  const playerBalanceExTimeoutRefetch = async () => {
    setIsDummyFetching(true);
    await refetch();
    setTimeout(() => setIsDummyFetching(false), 1000);
  };

  return (
    <div
      className={clsx(
        className,
        "lg:rounded-rounded lg:p-5 bg-athens-gray dark:bg-outer-space rounded-xs p-3",
      )}
    >
      <div className="lg:text-base font-bold truncate text-lg">
        {String(player?.displayableName)}
      </div>
      <div className="lg:mt-5 lg:text-base flex items-center justify-between font-bold text-lg text-keppel dark:text-java uppercase">
        <div>{$t({ defaultMessage: "Balance" })}</div>
        <PlayerBalance />
      </div>
      <div className="lg:mt-5 grid grid-cols-2 gap-5 mt-3">
        <Button
          className="lg:h-10 px-5 mobile-only:text-xs font-medium [text-transform:initial] h-8"
          type="primary-alternate"
          onClick={playerBalanceExTimeoutRefetch}
          disabled={isFetching || isDummyFetching}
        >
          <SVGIcon
            className={`${(isFetching || isDummyFetching) && "animate-spin"} *:size-4`}
            name="reload"
          />
          <span className="ml-1.5">{$t({ defaultMessage: "Refresh" })}</span>
        </Button>
        <Button
          className="lg:h-10 px-5 mobile-only:text-xs font-medium [text-transform:initial] relative h-8"
          type="primary-alternate"
          onClick={() =>
            emitter.emit("PLAYER_BALANCE_VISIBILITY_CHANGED", !playerSettings.isBalanceVisible)
          }
        >
          <div className="flex absolute">
            {playerSettings.isBalanceVisible ? (
              <>
                <SVGIcon name="closedEye" />
                <span className="ml-1.5">{$t({ defaultMessage: "Hide" })}</span>
              </>
            ) : (
              <>
                <SVGIcon name="openedEye" />
                <span className="ml-1.5">{$t({ defaultMessage: "Show" })}</span>
              </>
            )}
          </div>
          <div className="invisible flex">
            <SVGIcon name="closedEye" />
            <div>
              <div className="ml-1.5">{$t({ defaultMessage: "Hide" })}</div>
              <div className="ml-1.5">{$t({ defaultMessage: "Show" })}</div>
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
}
