import fortuneWheelBtnImg from "@assets/fw-btn-bg.png";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";

import { FortuneWheel } from "@entities/fortune-wheel";
import { formatTime, useFortuneWheelCountdown } from "@shared/lib";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data } = FortuneWheel.useWheelState();
  const countdown = useFortuneWheelCountdown(data?.nextSpinTime as number);
  const isCountdownActive = countdown > 0;

  return (
    <div className="bg-java/30 rounded-sm h-[5.25rem]">
      <div className="flex items-center justify-between gap-4 h-full p-3">
        <div className="relative min-w-24 size-24">
          <img
            className="absolute object-cover size-full animate-step-spin"
            src={fortuneWheelBtnImg}
            alt="fortune-wheel-preview"
            width="100%"
            height="100%"
          />
        </div>

        <Link
          className={`${isCountdownActive ? "bg-transparent" : "bg-black"} border-2 rounded-rounded border-java flex-center grow py-2 px-3 h-full hover:drop-shadow-[0_0_0.25rem_#13c7bc]`}
          to="/fortune-wheel"
        >
          <div
            className={`font-commissioner font-extrabold leading-5 italic uppercase text-java [text-shadow:0_0_1.25rem_#13c7bc] text-center whitespace-normal *:landscape:inline-block ${isCountdownActive ? "text-lg landscape:text-2xl" : "landscape:text-lg"}`}
          >
            {isCountdownActive ? (
              formatTime(countdown)
            ) : (
              <>
                <div>{$t({ defaultMessage: "fortune wheel" })}</div>{" "}
                <div>{$t({ defaultMessage: "ready to spin" })}</div>
              </>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
