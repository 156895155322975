import { apiClient } from "@lobby/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useAuth } from "@shared/lib";

export const FortuneWheel = {
  useWheelSettings() {
    return useQuery({
      refetchInterval: 1000 * 15,
      queryKey: ["FortuneWheel.getSettings"],
      queryFn: () =>
        apiClient.send({
          endpoint: "FortuneWheel.getSettings",
        }),
      select: (data) => data.result,
    });
  },
  useWheelWinner() {
    return useQuery({
      queryKey: ["FortuneWheel.getWin"],
      queryFn: () =>
        apiClient.send({
          endpoint: "FortuneWheel.getWin",
        }),
      select: (data) => data.result,
    });
  },
  useWheelSpin() {
    return useMutation({
      mutationKey: ["FortuneWheel.getWin"],
      mutationFn: () =>
        apiClient.send({
          endpoint: "FortuneWheel.getWin",
        }),
    });
  },
  useWheelState() {
    const { isAuth } = useAuth();

    return useQuery({
      enabled: isAuth,
      queryKey: ["Lobby.getCurrent"],
      queryFn: () =>
        apiClient.send({
          endpoint: "Lobby.getCurrent",
        }),
      select: (data) => data.result.fortuneWheel,
    });
  },
  useIsWheelAvailable() {
    const { data: fortuneWheel } = this.useWheelState();
    return Boolean(fortuneWheel?.isActive);
  },
};
