import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { memo, useEffect, useRef, useState } from "react";

import { GameCard } from "@entities/game";
import { useNavigateToCategory } from "@features/category/category-button";
import { CategoryIcon } from "@features/category/category-icon";
import { range } from "@shared/lib";
import { Button } from "@shared/ui";
import { Pagination } from "@shared/ui/pagination";

import type { Output } from "@lobby/api-client/src/codegen";

interface IGameListProps {
  className?: string;
  expanded?: boolean;
  expandable?: boolean;
  categoryId: string | number;
  type: string;
  label: string;
  items: Output<"Game.getList">["data"] | undefined;
}

export const GameList = memo(function GameList({
  className,
  items,
  expanded,
  expandable,
  categoryId,
  type,
  label,
}: IGameListProps) {
  const { $t } = useTranslate();
  const navigateToCategory = useNavigateToCategory();
  const [itemsCountToRender, setItemsCountToRender] = useState(10);
  const [isContentOverflow, setIsContentOverflow] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollComponentRef = useRef<HTMLDivElement>(null);
  const itemsCountRef = useRef(0);

  itemsCountRef.current = items?.length ?? 0;

  const hasIcon = type !== "search" && type !== "provider";

  useEffect(() => {
    const checkForContentOverflow = () => {
      const scrollContainer = scrollContainerRef.current;
      const scrollComponent = scrollComponentRef.current;

      if (scrollContainer && scrollComponent) {
        if (!expandable) {
          return setIsContentOverflow(false);
        }

        const isContentOverflow = scrollComponent.scrollWidth > scrollContainer.scrollWidth;
        setIsContentOverflow(isContentOverflow);
      }
    };

    checkForContentOverflow();
    window.addEventListener("resize", checkForContentOverflow);
    return () => window.removeEventListener("resize", checkForContentOverflow);
  }, [items, expandable]);

  useEffect(() => {
    const scrollListener = (ev: Event) => {
      ev.stopPropagation();

      const { scrollWidth, scrollLeft, clientWidth } = ev.currentTarget as HTMLDivElement;
      if (Math.abs(scrollWidth - clientWidth - scrollLeft) < 1) {
        setItemsCountToRender((prev) => Math.min(prev + 10, itemsCountRef.current));
      }
    };

    scrollComponentRef.current?.addEventListener("scroll", scrollListener);
    return () => scrollComponentRef.current?.removeEventListener("scroll", scrollListener);
  }, []);

  const itemsToRender = expanded ? items : items?.slice(0, itemsCountToRender);

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="lg:text-xl flex items-center gap-1.5 text-keppel font-bold text-xs uppercase dark:text-java">
          {hasIcon && <CategoryIcon id={categoryId} />}
          <span>{label}</span>
        </div>

        <div
          className={clsx(
            "flex items-center gap-5",
            (!expandable || !isContentOverflow) && "invisible",
          )}
        >
          <Pagination className="mobile-only:hidden" componentRef={scrollComponentRef.current} />
          <Button onClick={() => navigateToCategory(categoryId)}>
            {$t({ defaultMessage: "Show all" })}
          </Button>
        </div>
      </div>

      <div className="lg:mt-5 mt-3" ref={scrollContainerRef}>
        <div
          className={clsx(
            "lg:gap-x-5 lg:gap-y-10 grid gap-x-2 gap-y-5 peer empty:hidden",
            expanded
              ? "lg:grid-cols-[repeat(auto-fill,minmax(9rem,1fr))] grid-cols-[repeat(auto-fill,minmax(7rem,1fr))]"
              : "lg:grid-cols-[repeat(auto-fill,9rem)] lg:*:w-36 grid-cols-[repeat(auto-fill,7rem)] grid-flow-col *:w-28 overflow-x-auto scrollbar-thin snap-mandatory snap-x",
          )}
          ref={scrollComponentRef}
        >
          {itemsToRender
            ? itemsToRender.map(({ id, pic, providerName, name, stringId, isFavourite }, idx) => (
                <GameCard
                  key={idx}
                  imgSrc={pic}
                  title={name}
                  provider={providerName}
                  id={id}
                  gameStringId={stringId}
                  isFavourite={isFavourite}
                />
              ))
            : range(18)((idx) => <GameCard.Skeleton key={idx} />)}
        </div>
        <div className="text-xs lg:text-xl hidden peer-empty:block">
          {$t({ defaultMessage: "No games in this category" })}
        </div>
      </div>
    </div>
  );
});
