import { clsx } from "clsx";

export function Logo({ className }: { className?: string }) {
  return (
    <div className={clsx(className, "w-fit")}>
      <svg
        className="[aspect-ratio-2414/630.5]"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        viewBox="0 0 2414 630.5"
      >
        <path d="m295.1 138.29-65.17 418.96H114.35l65.18-418.96H295.1z" fill="#cda349" />
        <path
          d="m303.76 382.37-22.33 139.24H176.27L243.25 98.6h151.58c92.83 0 152.75 41.71 152.75 121.61 0 74.61-44.06 122.79-103.99 146.29l11.75 41.13c6.46 24.67 21.15 35.84 44.06 35.84 5.88 0 10.58-.59 15.27-1.17l-12.93 82.84c-8.81 2.35-21.74 4.7-41.71 4.7-47 0-74.61-20.56-92.83-81.08l-21.15-66.39h-42.3Zm30.55-193.29-17.04 107.51h52.29c37.01 0 68.15-18.8 68.15-62.86 0-30.55-22.33-44.65-52.29-44.65h-51.11ZM703.27 227.85 656.86 521.6H551.7l46.41-293.75h105.16ZM892.45 439.35c32.9 0 67.56-17.63 76.38-23.5l-15.27 93.41c-8.81 4.11-43.48 20.56-91.65 20.56-85.19 0-135.71-55.23-135.71-131.6 0-100.46 77.55-178.6 184.48-178.6 48.18 0 76.38 15.86 84.01 19.98l-14.69 94c-12.34-11.16-40.54-23.5-66.98-23.5-44.65 0-79.9 29.38-79.9 75.2 0 33.49 22.33 54.05 59.34 54.05ZM1173.27 98.6l-21.74 136.89c-2.35 13.51-4.7 27.61-6.46 33.49 20.56-23.5 58.16-49.35 98.7-49.35 62.86 0 96.94 38.78 84.01 118.68l-28.79 183.3h-104.58l26.44-167.44c3.52-22.91-2.94-40.54-25.26-40.54-32.9 0-58.16 25.26-63.45 60.51l-23.5 147.46h-105.16l66.98-423.01h102.81Z"
          className="fill-black dark:fill-white "
        />
        <path
          d="M1714.37 382.95c0 92.24-81.08 138.65-192.7 138.65h-150.99l66.98-423.01h140.41c85.19 0 148.64 32.9 148.64 103.99 0 52.29-33.49 83.43-68.15 96.94 29.96 9.4 55.81 41.71 55.81 83.43Zm-227.95 52.88h54.64c31.73 0 62.86-16.45 62.86-54.64 0-27.61-20.56-39.95-45.24-39.95h-56.99l-15.28 94.59Zm69.32-164.5c11.75 0 23.5-1.18 33.49-5.88 15.86-6.46 27.03-22.33 27.03-43.48 0-25.85-18.8-37.6-45.24-37.6h-44.65l-14.1 86.95h43.48ZM2042.78 335.95c0 27.03-9.4 52.29-11.16 59.93h-179.19c-1.17 31.14 22.91 47 62.86 47 42.89 0 89.89-12.92 102.23-18.8l-13.51 87.54c-17.04 7.64-58.16 18.21-112.21 18.21-94 0-146.88-55.23-146.88-132.19 0-100.46 82.84-178.01 178.6-178.01 81.66 0 119.26 53.46 119.26 116.33Zm-132.77-35.84c-27.61 0-45.83 21.15-50.53 47.59h84.01c4.7-26.44-5.29-47.59-33.49-47.59ZM2129.73 227.85l14.1-88.13h101.64l-14.1 88.13h72.85l-12.92 81.08h-72.85l-15.86 100.46c-4.11 26.44 12.34 34.66 29.96 34.66s36.43-6.46 48.76-11.75l-13.51 84.6c-14.1 6.46-43.48 12.93-80.49 12.93-49.35 0-91.06-21.74-92.24-80.49.59-9.4 1.17-19.98 2.94-31.14l17.04-109.28h-36.43l12.93-81.08h38.19Z"
          fill="#244bb4"
        />
        <path
          d="M713.15 155.51c0 7.48-1.47 14.75-4.37 21.58a54.69 54.69 0 0 1-11.88 17.62 54.8 54.8 0 0 1-17.62 11.88c-6.83 2.89-14.1 4.36-21.58 4.36a55.2 55.2 0 0 1-39.21-16.24 55.1 55.1 0 0 1-16.23-39.2 55.19 55.19 0 0 1 16.23-39.21 55.15 55.15 0 0 1 39.21-16.23 55.07 55.07 0 0 1 39.2 16.23c5.1 5.1 9.1 11.03 11.88 17.62a55.17 55.17 0 0 1 4.37 21.59"
          className="fill-[#231f20] dark:fill-white"
        />
        <path
          d="M703.7 136.08a49.72 49.72 0 0 0-10.7-15.87 49.83 49.83 0 0 0-54.73-10.7 49.72 49.72 0 0 0-15.87 10.7 49.83 49.83 0 0 0-10.7 54.73c2.51 5.94 6.11 11.28 10.7 15.87a49.77 49.77 0 0 0 35.3 14.62 49.64 49.64 0 0 0 35.3-14.62 49.83 49.83 0 0 0 10.7-54.73m-37.26 60.57h-17.47l-7.3-11.31-1.9-2.95 5.9-9.31 2.29-3.62h19.48l1 1.57 7.2 11.35-7.25 11.25-1.95 3.02Zm7.46-79.3 15.01 9.58v15.08l-14.39 4.77L659 135.56v-1.87l-.01-4.54.02-7.28 8.65-2.63 6.26-1.9Zm18.55 59.28-8.75 2.53-6 1.74-5.26-8.3-2.92-4.6 1.02-3.3 4.77-15.5 14.29-4.74 8.43 14.24-5.59 17.94Zm-50.94-59.28 6.45 1.96 8.45 2.57.02 7.28v4.56h-.02v1.84l-15.52 11.22-5.91-1.96-8.48-2.81v-15.08l15.01-9.58Zm-15.71 27.11 14.29 4.74 3.44 11.17 2.35 7.64-7.98 12.59-.2.32-5.1-1.48-9.65-2.79-5.59-17.94 8.43-14.24Zm67.26-19.49h-2.52l-15.35-9.8-.86-3.35a46.84 46.84 0 0 1 18.73 13.15m-51.98-13.15-.86 3.35-15.35 9.8h-2.52a47.04 47.04 0 0 1 18.73-13.15m-30.02 46.73 3.93 1.04 2.97 9.53 2.64 8.48-1.69 3.95a46.4 46.4 0 0 1-7.84-23m36.36 42.56 1.48-1.92h17.6l1.48 1.92a47.07 47.07 0 0 1-20.56 0m49.07-19.57-1.69-3.95 5.61-18.01 3.93-1.04a46.53 46.53 0 0 1-7.84 23"
          fill="#cda349"
        />
      </svg>
    </div>
  );
}
