import { Tab } from "@headlessui/react";
import { Fragment, type ReactNode } from "react";

export type TabsProps = {
  items: TabItemType[];
};

export type TabItemType = {
  label: ReactNode;
  children: ReactNode;
};

export default function Tabs({ items }: TabsProps) {
  return (
    <Tab.Group>
      <Tab.List className="grid grid-cols-[repeat(auto-fit,minmax(1px,1fr))] border-b-1 text-blue-bayoux">
        {items.map(({ label }, idx) => (
          <Tab as={Fragment} key={idx}>
            {({ selected }) => (
              <button
                className={`lg:border-b-3 lg:text-lg lg:p-5 lg:pt-2.5 border-b-2 font-bold text-sm p-2 whitespace-normal focus-visible:outline-0
                  ${selected ? "border-current text-keppel dark:text-java" : "border-transparent"}`}
              >
                {label}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="lg:mt-7.5 mt-5 h-full *:h-full min-h-0">
        {items.map((item, idx) => (
          <Tab.Panel key={idx}>{item.children}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
