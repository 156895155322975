import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import clsx from "clsx";

import { useAuth } from "@shared/lib";

import { Game } from "../model";

import { FavouriteBtn } from "./favourite-btn";

interface IGameCardProps {
  imgSrc: string;
  title: string;
  provider: string;
  id: number;
  gameStringId: string;
  isFavourite: boolean;
}

export function GameCard({
  imgSrc,
  title,
  provider,
  id,
  gameStringId,
  isFavourite,
}: IGameCardProps) {
  const { $t } = useTranslate();
  const { isAuth } = useAuth();
  const favouriteMutation = Game.useFavouriteSetter();

  return (
    <div className="lg:rounded-rounded bg-white dark:bg-ebony-clay border-1 border-transparent rounded-xs overflow-hidden snap-start hover:border-java relative group">
      <div className="h-full">
        <div className="bg-blue-bayoux aspect-square">
          <img
            className="object-contain size-full"
            src={imgSrc}
            alt={title}
            loading="lazy"
            width="100%"
            height="100%"
          />
        </div>
        <div className="lg:px-2.5 lg:h-[3.5rem] flex flex-col justify-center px-2 h-[2.3125rem]">
          <div className="text-blue-bayoux font-medium text-3xs lg:text-xs">{provider}</div>
          <div
            className="dark:text-white font-semibold text-3xs lg:text-xs lg:mt-1 truncate"
            title={title}
          >
            {title}
          </div>
        </div>
      </div>

      <Link
        className="lg:group-hover:opacity-100 absolute inset-0 opacity-0"
        to="/game/$gameId"
        search
        params={{
          gameId: gameStringId,
        }}
      >
        <div className="lg:h-14 bg-java flex items-center justify-center font-bold uppercase text-ebony-clay text-xs lg:text-base absolute bottom-0 h-[2.3125rem] w-full">
          {$t({
            defaultMessage: "play",
          })}
        </div>
      </Link>
      {isAuth && (
        <FavouriteBtn
          className={clsx(
            "lg:top-2 lg:right-2 absolute top-1.5 right-1.5 mobile-only:hidden",
            isFavourite ? "visible" : "invisible group-hover:visible",
          )}
          isFavourite={isFavourite}
          onClick={() => favouriteMutation.mutate({ gameId: id, isFavourite: !isFavourite })}
        />
      )}
      {favouriteMutation.isPending && (
        <div className="bg-white dark:bg-bright-gray bg-opacity-60 dark:bg-opacity-60 absolute inset-0" />
      )}
    </div>
  );
}
