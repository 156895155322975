import fortuneWheelBtnImg from "@assets/fw-btn-bg.png";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";

import { FortuneWheel } from "@entities/fortune-wheel";
import { formatTime, type TFortuneWheelSettings, useFortuneWheelCountdown } from "@shared/lib";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data } = FortuneWheel.useWheelState();
  const { data: settings } = FortuneWheel.useWheelSettings();

  const countdown = useFortuneWheelCountdown(data?.nextSpinTime as number);

  const isCountdownActive = countdown > 0;

  function isDepositAbsent(settings: TFortuneWheelSettings) {
    return settings.state === "make_deposit";
  }

  return (
    <div className="mt-5 relative h-[5.625rem] overflow-hidden">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <img
          className="object-contain size-36 animate-step-spin"
          src={fortuneWheelBtnImg}
          alt="fortune-wheel-preview"
          width="100%"
          height="100%"
        />
      </div>
      <Link
        className={`${isCountdownActive ? "bg-midnight-express" : "bg-black"} border-2 rounded-rounded border-java py-1 px-2.5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-44 hover:drop-shadow-[0_0_0.25rem_#13c7bc]`}
        to="/fortune-wheel"
      >
        <div className="font-commissioner font-extrabold italic uppercase text-java [text-shadow:0_0_1.25rem_#13c7bc] text-center whitespace-nowrap">
          {isCountdownActive
            ? formatTime(countdown)
            : isDepositAbsent(settings ?? ({} as TFortuneWheelSettings))
              ? $t({ defaultMessage: "fortune wheel" })
              : $t({ defaultMessage: "ready to spin" })}
        </div>
      </Link>
      <hr className="border-none [background-image:linear-gradient(to_right,transparent,#13c7bc_35%,#13c7bc_65%,transparent)] absolute top-0 left-0 h-1 w-full" />
      <hr className="border-none [background-image:linear-gradient(to_right,transparent,#13c7bc_35%,#13c7bc_65%,transparent)] absolute bottom-0 left-0 h-1 w-full" />
    </div>
  );
}
