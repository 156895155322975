import { apiClient } from "@lobby/api-client";
import { useIntlT } from "@lobby/ocb-intl";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { emitter, useAuth } from "@shared/lib";

import type { ApiClientResponse, SendOptions } from "@lobby/api-client";
import type { Output } from "@lobby/api-client/src/codegen";

export type TGames = Output<"Game.getList">["data"];
export type TGame = TGames[number];

type TGameRunParams = Omit<NonNullable<SendOptions<"Game.run">["params"]>, "isDemo">;

class GameModel {
  categoriesMap: Record<string, Record<number, string>> = {};
  providersMap = new Map<number, string>();

  useNoJackpotGames() {
    return useQuery({
      queryKey: ["Game.getNoJackpotGames"],
      queryFn: () => apiClient.send({ endpoint: "Game.getNoJackpotList" }),
      select: (data) => data.result,
    });
  }

  useNoWagerGames() {
    return useQuery({
      queryKey: ["Game.getNoWagerGames"],
      queryFn: () => apiClient.send({ endpoint: "Game.getNoWagerList" }),
      select: (data) => data.result,
    });
  }

  useGameRun(params: TGameRunParams) {
    return useQuery({
      queryKey: ["Game.run", params],
      staleTime: 0,
      gcTime: 0,
      queryFn: () =>
        apiClient.send({
          endpoint: "Game.run",
          params: {
            ...params,
            isDemo: false,
            // demoBalance: 0,
          },
        }),
    });
  }

  selectCategoryList(data: ApiClientResponse<"Game.getCategoriesList">) {
    return data?.result?.data ?? [];
  }

  useCategoriesList() {
    const {
      intl: { locale, defaultLocale },
    } = useIntlT();

    const lang = locale || (defaultLocale as any);

    return useQuery({
      queryKey: ["Game.getCategoriesList", lang],
      queryFn: async () => {
        const result = await apiClient.send({
          endpoint: "Game.getCategoriesList",
          params: {
            lang,
          },
        });

        if (result.result) {
          this.setCategoriesMap(result.result.data, lang);
        }

        return result;
      },
      select: this.selectCategoryList,
    });
  }

  selectGameList(data: ApiClientResponse<"Game.getList">) {
    return data?.result?.data ?? [];
  }

  useList() {
    return useQuery({
      queryKey: ["Game.getList"],
      queryFn: () => apiClient.send({ endpoint: "Game.getList" }),
      select: this.selectGameList,
    });
  }

  useProvidersList() {
    return useQuery({
      queryKey: ["Game.getProvidersList"],
      queryFn: async () => {
        const result = await apiClient.send({ endpoint: "Game.getProvidersList" });

        if (result.result) {
          this.setProvidersMap(result.result.data);
        }

        return result;
      },
    });
  }

  useRecommendedGames() {
    const queryClient = useQueryClient();
    const { isAuth } = useAuth();

    const result = useQuery({
      enabled: isAuth,
      queryKey: ["Game.getRecommendations"],
      queryFn: () => apiClient.send({ endpoint: "Game.getRecommendations" }),
      select: (data) => data.result,
    });

    useEffect(() => {
      const unbind = emitter.on("PLAYER_LOGOUT", () => {
        queryClient.removeQueries({ queryKey: ["Game.getRecommendations"] });
        queryClient.invalidateQueries({ queryKey: ["Game.getList"] });
      });

      return unbind;
    }, []);

    return result;
  }

  useFavouriteSetter() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params: SendOptions<"Game.updateFavouriteStatus">["params"]) =>
        apiClient.send({ endpoint: "Game.updateFavouriteStatus", params }),
      onSuccess: (data, variables) => {
        if (data.result === "success") {
          queryClient.setQueryData<ApiClientResponse<"Game.getList">>(
            ["Game.getList"],
            (oldData) => {
              const oldGameList = oldData?.result.data;

              if (!oldGameList) return oldData;

              const gameIndex = oldGameList.findIndex((game) => game.id === variables?.gameId);
              if (gameIndex !== -1) {
                const newGameList = [...oldGameList];
                newGameList[gameIndex] = {
                  ...oldGameList[gameIndex],
                  isFavourite: !oldGameList[gameIndex].isFavourite,
                };

                return {
                  ...oldData,
                  result: {
                    ...oldData.result,
                    data: newGameList,
                  },
                };
              } else {
                return oldData;
              }
            },
          );
        }
      },
    });
  }

  getGameByStringId(gameStringId: string) {
    return this.useList().data?.find((game) => game.stringId === gameStringId);
  }

  getCategoryNameById(id: string | number, lang: string) {
    return this.categoriesMap[lang]?.[Number(id)] ?? "";
  }

  getProviderNameById(id: string | number) {
    return this.providersMap.get(Number(id));
  }

  private setCategoriesMap(data: Output<"Game.getCategoriesList">["data"], lang: string) {
    this.categoriesMap[lang] = {};

    data.forEach(({ id, name }) => (this.categoriesMap[lang][id] = name));
  }

  private setProvidersMap(data: Output<"Game.getProvidersList">["data"]) {
    this.providersMap.clear();

    data.forEach(({ id, name }) => {
      this.providersMap.set(id, name);
    });
  }
}

export const Game = new GameModel();
