import { apiClient } from "@lobby/api-client";

import type { ApiClientResponse } from "@lobby/api-client";
import type { QueryClient } from "@tanstack/react-query";

export async function loadFortuneWheelSettings(queryClient: QueryClient) {
  return queryClient.ensureQueryData<ApiClientResponse<"FortuneWheel.getSettings">>({
    queryKey: ["FortuneWheel.getSettings"],
    queryFn: () =>
      apiClient.send({
        endpoint: "FortuneWheel.getSettings",
      }),
  });
}
