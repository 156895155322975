import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";

import { Player } from "@entities/player";
import { APIError, useErrorTranslate } from "@shared/lib";
import { Button, Input } from "@shared/ui";

interface ILoginFormProps {
  className?: string;
  mayShowError?: boolean;
}

export function LoginForm({ className, mayShowError = true }: ILoginFormProps) {
  const { $t } = useTranslate();
  const [error, setError] = useState<APIError>();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { formatMessage } = useErrorTranslate();

  const password = $t({ defaultMessage: "Password" });
  const login = $t({ defaultMessage: "Login" });

  const loginMutation = Player.useLogIn();

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const login = usernameRef.current?.value;
    const password = passwordRef.current?.value;

    if (login && password) {
      loginMutation.mutate({ login, password });
    }
  }

  const errorData = loginMutation.data?.error;
  const showError = mayShowError && error;

  useEffect(() => {
    if (errorData) {
      const apiError = new APIError(errorData.message, { code: errorData.code });
      setError(apiError);
    }
  }, [errorData]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Input
        ref={usernameRef}
        label={login}
        type="text"
        name="login"
        placeholder={login}
        autoComplete="username"
        minLength={3}
        autoFocus
        required
        defaultValue=""
      />
      <Input
        ref={passwordRef}
        className="mt-5"
        label={password}
        type="password"
        name="password"
        placeholder={password}
        autoComplete="current-password"
        minLength={6}
        required
      />
      <div
        className={`mt-3 text-center text-radical-red text-sm ${showError ? "visible" : "invisible"}`}
      >
        {formatMessage(error || "ok")}
      </div>
      <div className="mt-3">
        <Button className="mx-auto" type="primary" htmlType="submit">
          {$t({ defaultMessage: "Log In" })}
        </Button>
      </div>
    </form>
  );
}
