import { ApiError } from "./error";

import type { Endpoint, Input, Output } from "./codegen";

export interface ApiClientResponse<E extends Endpoint> {
  id: number;
  jsonrpc: "2.0";
  error?: {
    code: number;
    message: string;
    data: unknown;
  };
  result: Output<E>;
}

export type SendOptions<E extends Endpoint> = {
  params?: Input<E>["body"];
  endpoint: E;
  signal?: AbortSignal;
  headers?: Record<string, string>;
};

export class ApiClient {
  // private static BASE_URL = window.location.origin + '/api'
  private id = 1;

  async send<E extends Endpoint>({
    endpoint,
    params,
    signal,
    headers,
  }: SendOptions<E>): Promise<ApiClientResponse<E>> {
    const request = new Request("/rpc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: this.id++,
        method: endpoint,
        params: params,
      }),
      signal,
    });
    const response = await fetch(request);
    const result = await response.json();

    // if (
    //   response.status === 401 &&
    //   endpoint !== 'GET /api/v1/users/me' &&
    //   endpoint !== 'POST /api/v1/login'
    // ) {
    //   window.location.pathname = '/log-in'
    //   return null as never
    // }

    if (response.status !== 200) {
      const data = result?.data;
      const code = data?.code;
      const message = data?.message;
      throw new ApiError(code ?? -1, message ?? "Unknown error");
    }

    return result;
  }
}

export const apiClient = new ApiClient();
