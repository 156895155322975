import { apiClient } from "@lobby/api-client";
import { useIntlT } from "@lobby/ocb-intl";
import { useQuery } from "@tanstack/react-query";

import { useAuth, useMobile } from "@shared/lib";

export const Lobby = {
  useLobby() {
    const { isAuth } = useAuth();

    return useQuery({
      enabled: isAuth,
      queryKey: ["Lobby.getCurrent"],
      queryFn: () =>
        apiClient.send({
          endpoint: "Lobby.getCurrent",
        }),
      select: (data) => data.result,
    });
  },
  useBanner() {
    const {
      intl: { locale, defaultLocale },
    } = useIntlT();
    const isMobile = useMobile();

    return useQuery({
      queryKey: ["Slider.getSimplified", locale || defaultLocale, isMobile],
      queryFn: () =>
        apiClient.send({
          endpoint: "Slider.getSimplified",
          params: {
            name: "test",
            lang: locale || defaultLocale,
            deviceType: isMobile ? "mobile" : "desktop",
          },
        }),
      select: (data) => data.result,
    });
  },
};
