import { memo } from "react";

import { Logo, Modal } from "@shared/ui";

import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  useProfileSections,
} from "../common";

import type { TProfileSectionItem } from "../common";

interface IProfileComponentDesktopProps {
  currentSection: TProfileSectionItem;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentDesktop = memo(function ProfileComponentDesktop({
  currentSection = {} as TProfileSectionItem,
  showLoadingOverlay,
  close,
}: IProfileComponentDesktopProps) {
  const sections = useProfileSections();

  return (
    <Modal.Panel className="border-1 rounded-rounded p-5 top-18 w-[62.5rem] bg-mercury border-keppel dark:border-shark dark:bg-ebony-clay h-[45.625rem]">
      <Modal.Title className="justify-between">
        <Modal.CloseButton onClick={close} />
        <Logo className="h-11" />
      </Modal.Title>

      <div className="grid grid-cols-[minmax(18.5rem,22rem)_1fr] gap-10 min-h-0 *:min-h-0">
        <div>
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails className="mt-5" />
          <div className="mt-5">
            <ProfileSectionControls currentSectionId={currentSection.id} items={sections} />
          </div>
        </div>
        <div className="flex flex-col grow">
          <div className="font-bold text-xl uppercase mb-5">{currentSection.title}</div>
          {currentSection.component}
        </div>
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
