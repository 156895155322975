import { useContext } from "react";

import { LobbySettingsContext } from "@entities/lobby";
import { getCategoryIconName, getCategoryImageSrc, isClientCategory } from "@shared/lib";
import { SVGIcon } from "@shared/ui";

import type { TIconName } from "@shared/ui";
import type { CSSProperties } from "react";

interface ICategoryIconProps {
  className?: string;
  id: string | number;
}

export function CategoryIcon({ className, id }: ICategoryIconProps) {
  const lobbyContext = useContext(LobbySettingsContext);

  return (
    <div className={className}>
      {isClientCategory(id) ? (
        <SVGIcon className="size-4 lg:size-5" name={getCategoryIconName(String(id)) as TIconName} />
      ) : (
        <div
          className="size-4 lg:size-5"
          style={
            {
              backgroundColor: "currentcolor",
              mask: `url(${getCategoryImageSrc(lobbyContext.theme, id)}) center/100% no-repeat`,
            } as CSSProperties
          }
        ></div>
      )}
    </div>
  );
}
