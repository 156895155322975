import { Listbox } from "@headlessui/react";
import { useIntlT } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useEffect } from "react";

import { emitter } from "@shared/lib";
import { localesMap } from "@shared/lib/locales";

import { flagIcons } from "./flag-icons";

import type { TLocale } from "@shared/lib/locales";

interface ILanguageSelectorProps {
  className?: string;
}

export function LanguageSelector({ className }: ILanguageSelectorProps) {
  const { intl } = useIntlT();

  const locale = intl.locale as TLocale;

  function handleLanguageChange(locale: TLocale) {
    emitter.emit("SET_LOCALE", locale);
    localStorage.setItem("locale", locale);
  }

  useEffect(() => {
    intl.locale = localStorage.getItem("locale") ?? "en";
  }, [intl, locale]);

  return (
    <Listbox value={locale} onChange={handleLanguageChange}>
      {({ open }) => (
        <div
          className={clsx(
            className,
            "rounded-rounded flex items-center relative p-2.5",
            open && "bg-athens-gray dark:bg-bright-gray",
          )}
        >
          <Listbox.Button className="flex items-center">
            <div className="rounded-full flex justify-center size-5 overflow-hidden mr-1">
              {flagIcons[locale]}
            </div>
            <span className="font-medium text-sm mr-1.5 capitalize">{localesMap[locale].name}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="7"
              fill="none"
              viewBox="0 0 7 7"
            >
              <path
                className="fill-outer-space dark:fill-white"
                d="M4.366 6a1 1 0 0 1-1.732 0L.469 2.25a1 1 0 0 1 .866-1.5h4.33a1 1 0 0 1 .866 1.5L4.366 6Z"
              />
            </svg>
          </Listbox.Button>
          <Listbox.Options className="lg:translate-y-1 bg-athens-gray dark:bg-bright-gray rounded-rounded rounded-tl-none p-5 absolute left-0 -bottom-2 translate-y-1/4">
            <div className="lg:gap-2.5 flex flex-col gap-5 font-medium text-sm">
              {Object.values(localesMap).map((lang) => (
                <Listbox.Option key={lang.code} value={lang.code}>
                  {({ active, selected }) => (
                    <button
                      className={clsx(
                        (active || selected) && "text-keppel dark:text-java",
                        "flex items-center gap-1 text-left",
                      )}
                    >
                      <div className="flex justify-center rounded-full size-5 overflow-hidden">
                        {flagIcons[lang.code as TLocale]}
                      </div>
                      {lang.name}
                    </button>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
}
